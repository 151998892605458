.content {
  padding: 20px;
  flex-grow: 1;
}

.dashboard-list {
  display: flex; /* Use flexbox for a row layout */
  flex-wrap: wrap; /* Allow wrapping to new rows if necessary */
  gap: 20px; /* Add equal spacing between items */
  flex-direction: row;
}

.dashboard-button {
  position: relative;
  width: 250px; /* Set a consistent width */
  aspect-ratio: 1; /* Maintain square shape */
  border: none;
  color: white;
  padding: 0;
  margin: 0;
  cursor: pointer;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.dashboard-text {
  position: relative;
  z-index: 2; /* Text above everything */
  padding: 10px; /* Padding for aesthetics */
  font-size: 24px; /* Text size */
  margin-left: 10px; /* Move text a bit to the right */
  margin-bottom: 10px; /* Move text up a bit from the bottom */
}

.dashboard-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 40%); 
  z-index: 1; /* Overlay below the text */
}

.dashboard-button:hover {
  color: white;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  transform: translateY(-5px);
}

.dashboard-button span {
  position: relative;
  z-index: 2; /* Ensures text is on top */
  font-size: 24px;
}

.pdf-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.pdf-item {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.download-pdf {
  text-decoration: none;
  font-weight: 500;
}
