.edit-customer-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .content {
    width: 100vh;
    padding: 20px;
    border-radius: 8px;
    flex-grow: 1;
  }
  
  h2 {
    margin-top: 0;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  .edit-customer-page input[type="text"],
  .edit-customer-page input[type="email"],
  .edit-customer-page input[type="password"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .checkbox-group {
    display: flex;
    align-items: center;
  }
  
  button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }