.customer-detail-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding: 20px;
  background-color: #f9f9f9;
}

.customer-detail-card {
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.customer-detail-title {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333;
}

.customer-detail-info {
  font-size: 18px;
  margin-bottom: 20px;
  color: #666;
}

.customer-detail-info p {
  margin-bottom: 10px;
}

.practices-title {
  font-size: 22px;
  margin-top: 20px;
  color: #333;
}

.practices-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.practices-list li {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 5px;
  background-color: #f9f9f9;
}

.practices-list li:hover {
  background-color: #e0e0e0;
}

.practices-list button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  background-color: #333;
  width: 100px; /* Adjust the width as needed */
}

.practices-list .practice-name {
  flex: 1; /* Take up remaining space */
}

.departments {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.departments label {
  margin-right: 10px;
}
