.add-report-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .content {
    padding: 20px;
  }

  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    font-size: 18px;
    margin-bottom: 8px;
  }
  
  .large-textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
  }
  
  .large-textarea:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .submit-button {
    display: block;
    width: 100%;
    padding: 10px 20px;
    font-size: 18px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }

  .search-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .practice-dropdown {
    max-height: 150px;
    overflow-y: auto;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .practice-dropdown-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .practice-dropdown-item:hover {
    background-color: #f0f0f0;
  }
  
  .selected-practice {
    margin-top: 10px;
    font-size: 16px;
    color: #007bff;
  }
  
  .large-textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
  }
  
  .submit-button {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  