@import '../../colors.css';

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--digi-pink);
    color: #ffffff;
    padding: 10px;
    padding-left: 20px;
  }

  .right {
    display: flex;
  }

  .user-name {
    color: #fff;
    padding: 12px 20px;
    border-radius: 5px; /* Rounded corners */
    font-size: 1.2rem;
  }

  .splitser {
    color: #fff;
    padding: 10px 2px;
    border-radius: 5px; /* Rounded corners */
    font-size: 1.2rem;
  }

  .logout-button {
    border: none;
    padding: 12px 20px;
    cursor: pointer;
    border-radius: 5px; /* Rounded corners */
    font-size: 1.2rem;
  }
  
  .logout-button:hover {
    background-color: #007fbe; /* Darker greenish color on hover */
  }