.content {
    padding: 20px;
    flex-grow: 1;
}

.reports-list {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Increase spacing */
}

.report-item {
    padding-left: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    transition: background-color 0.3s, box-shadow 0.3s;
    cursor: pointer; /* Indicate it's clickable */
}

.report-item:hover {
    background-color: #f1f1f1; /* Subtle hover effect */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}