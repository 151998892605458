.content {
  padding: 20px;
  flex-grow: 1;

  .search-input {
    margin-bottom: 20px;
    width: 25%;
  }
}

.user-table {
  width: 100%;
  border-collapse: collapse;
}

.user-table tr:hover {
  background-color: #f0f0f0; /* Light grey color */
}

.user-table th, .user-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.user-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

