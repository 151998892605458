.content {
    padding: 20px;
    flex-grow: 1;
  }

.dashboard-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.dashboard-table th, .dashboard-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.dashboard-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}