.login-container {
  flex-grow: 1;
}

.login-form {
  background-color: #ffffff;
  padding: 20px;
}

.login-form h1 {
  margin-bottom: 20px;
}

.login-form p {
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 15px;
}

.input-group input {
  width: 40%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn-login {
  width: 10%;
  padding: 10px;
  font-size: 16px;
  background-color: #69B342;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-login:hover {
  background-color: #27ae60;
}