.content {
    padding: 20px;
    flex-grow: 1;
  }
  
  .practice-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .practice-button {
    border: none;
    color: white;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 50px;
    border: 2px solid white;
  }
  
  .practice-button:hover {
    background-color: white;
    color: black;
  }
  