.pdf-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .pdf-item {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  .download-pdf {
    text-decoration: none;
    font-weight: 500;
  }