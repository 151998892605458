.practice-detail-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .practice-detail-card {
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
  
  .practice-detail-title {
    font-size: 28px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .practice-detail-info {
    font-size: 18px;
    margin-bottom: 20px;
    color: #666;
  }
  
  .practice-detail-info p {
    margin-bottom: 10px;
  }
  
  .dashboards-title {
    font-size: 22px;
    margin-top: 20px;
    color: #333;
  }
  
  .dashboards-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
  }

  .dashboard-type {
    margin-right: 20px;
  }
  
  .dashboards-list li {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 5px;
    background-color: #f9f9f9;
  }
  
  .dashboards-list li:hover {
    background-color: #e0e0e0;
  }
  
  .dashboards-list button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    background-color: #333;
    width: 100px; /* Adjust the width as needed */
  }
  
  .dashboards-list .dashboard-name {
    flex: 1; /* Take up remaining space */
  }

  .download-pdf:hover {
    cursor: pointer;
  }

  .pdf-list {
    display: flex;
    flex-direction: column;
    gap: 5px; /* Space between items */
  }
  
  .pdf-item {
    padding: 10px;
    border: 1px solid #ddd; /* Light border for separation */
    border-radius: 5px; /* Rounded corners */
    background-color: #f9f9f9; /* Light background color */
    transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition effects */

    display: flex; /* Make it a flex container */
    justify-content: space-between; /* Push the trash icon to the far right */
    align-items: center; /* Align items vertically */
  }
  
  .download-pdf {
    text-decoration: none; /* Remove underline from links */
    font-weight: 500; /* Slightly bold text */
  }

  .report-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .report-table th, .report-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .report-table th {
    background-color: #f9f9f9;
    font-weight: bold;
  }
  
  .report-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .report-table tr td a {
    color: #007bff; /* Link color for PDF downloads */
    text-decoration: none;
  }
  
  .report-table tr td a:hover {
    text-decoration: underline;
  }
  
  .report-table td {
    color: #333; /* Text color for table data */
  }

  