.content {
    padding: 20px;
    flex-grow: 1;
  }

.practice-table {
  width: 100%;
  border-collapse: collapse;
}

.practice-table tr:hover {
  background-color: #f0f0f0; /* Light grey color */
}

.practice-table th, .practice-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.practice-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.confirmation-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.confirmation-dialog p {
  margin-bottom: 20px;
}

.confirmation-dialog button {
  margin-right: 10px;
}